import { maxLength, requiredIf } from 'vuelidate/lib/validators';

// const plateCheck = helpers.regex(
//   'plateCheck',
//   /^(0[1-9]|[1-7][0-9]|8[01])(([A-Z])(\d{4,5})|([A-Z]{2})(\d{3,4})|([A-Z]{3})(\d{2,4}))$/,
// );

const damageValidations = {
  validations: {
    damage: {
      tramerPrice: {
        required: requiredIf((x) => x.hasTramer),
      },
      // licensePlate: {
      //   required,
      //   plateCheck,
      // },
      chassisNumber: {
        maxLength: maxLength(17),
      },
    },
  },
  methods: {
    tramerPriceErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.damage.tramerPrice;
      if (!$dirty) return errors;
      !required && errors.push('Lütfen bir tramer tutarı giriniz.');
      return errors;
    },

    chassisNumberErrors() {
      const errors = [];
      const { $dirty, maxLength } = this.$v.damage.chassisNumber;
      if (!$dirty) return errors;
      !maxLength && errors.push('Şase numarası en fazla 17 karakter olabilir.');
      return errors;
    },
  },
};

export default damageValidations;
