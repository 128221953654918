/* eslint-disable no-unused-expressions */
import { required } from 'vuelidate/lib/validators';

const infoValidations = {
  validations: {
    carInfo: {
      title: {
        required,
      },
      year: {
        required,
      },
      brand: {
        required,
      },
      model: {
        required,
      },
      bodyType: {
        required,
      },
      transmissionType: {
        required,
      },
      fuelType: {
        required,
      },
      version: {
        required,
      },
      kilometer: {
        required,
      },
      color: {
        required,
      },
    },
  },
  methods: {
    titleErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.carInfo.title;
      if (!$dirty) return errors;
      !required && errors.push(`Lütfen bir başlık giriniz.`);
      return errors;
    },
    modelErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.carInfo.model;
      if (!$dirty) return errors;
      !required && errors.push(`Lütfen bir model seçin.`);
      return errors;
    },
    bodyTypeErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.carInfo.bodyType;
      if (!$dirty) return errors;
      !required && errors.push(`Lütfen bir gövde tipi seçin.`);
      return errors;
    },
    transmissionTypeErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.carInfo.transmissionType;
      if (!$dirty) return errors;
      !required && errors.push(`Lütfen bir vites tipi seçin.`);
      return errors;
    },
    fuelTypeErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.carInfo.fuelType;
      if (!$dirty) return errors;
      !required && errors.push(`Lütfen bir yakıt tipi seçin.`);
      return errors;
    },
    versionErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.carInfo.version;
      if (!$dirty) return errors;
      !required && errors.push(`Lütfen bir versiyon seçin.`);
      return errors;
    },
    kilometersErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.carInfo.kilometer;
      if (!$dirty) return errors;
      !required && errors.push(`Lütfen aracınızın kilometresini girin.`);
      return errors;
    },
    colorErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.carInfo.color;
      if (!$dirty) return errors;
      !required && errors.push(`Lütfen renk seçin.`);
      return errors;
    },
  },
};

export default infoValidations;
