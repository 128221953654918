<template>
  <div class="fill-height">
    <v-card-actions>
      <v-btn fab plain small @click="$router.push({ name: 'garage' })">
        <v-icon v-text="'fas fa-chevron-left'"></v-icon>
      </v-btn>
    </v-card-actions>
    <div class="preloader" v-if="!dataIsReady">
      <v-progress-circular indeterminate size="50" color="primary" width="5"></v-progress-circular>
    </div>
    <keep-alive>
      <info
        ref="info"
        v-if="isInfoComponent && dataIsReady"
        @process="changeProcess"
        :allow="{ allowCarInsert, allowDreamCarInsert }"
      />
      <damage ref="damage" v-if="isDamageComponent && dataIsReady" @process="changeProcess" />
    </keep-alive>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FETCH_PRESET } from '@/store/modules/garage.module';
import Info from './components/info/Info.vue';
import Damage from './components/damage/Damage.vue';

export default {
  components: { Info, Damage },
  name: 'AddNewCar',
  data() {
    return {
      component: 'Info',
      dataIsReady: false,
      id: null,
    };
  },
  computed: {
    ...mapGetters(['allowCarInsert', 'allowDreamCarInsert']),
    isInfoComponent() {
      return this.component === 'Info';
    },
    isDamageComponent() {
      return this.component === 'Damage';
    },
    carType() {
      return this.$route.query.type;
    },
  },
  created() {
    this.$nextTick(() => {
      this.id = this.$route.params.id ?? '';
      this.fetchPreset(this.id)
        .then(() => {
          this.dataIsReady = true;
        })
        .catch(() => {
          this.$router.push({ name: 'garage' });
        });
    });
  },
  methods: {
    ...mapActions({
      fetchPreset: FETCH_PRESET,
    }),
    changeProcess(component) {
      this.component = component;
    },
  },
};
</script>

<style lang="scss" scoped>
.preloader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
