<template>
  <div>
    <v-card color="transparent d-flex justify-center align-center" flat v-if="!allowCarInsert && !allowDreamCarInsert">
      <v-card-text class="text-center"> Daha fazla araç ekleyemezsiniz. </v-card-text>
    </v-card>

    <v-card color="transparent" flat v-else>
      <v-card-text>
        <div class="d-flex justify-space-between flex-row">
          <v-btn-toggle v-model="carInfo.isDreamCar" tile color="primary" mandatory group class="mx-auto">
            <v-btn class="text-capitalize" :value="false" min-width="125" :disabled="!allowCarInsert">
              <v-icon v-text="'fa-car-side'" left></v-icon> Aracım
            </v-btn>
            <v-btn class="text-capitalize" :value="true" min-width="125" :disabled="!allowDreamCarInsert">
              <v-icon v-text="'fa-car'" left></v-icon> Hayalimdeki araç
            </v-btn>
          </v-btn-toggle>
        </div>
        <v-row dense>
          <v-col cols="12">
            <v-subheader>Başlık *</v-subheader>
            <v-text-field
              label="Başlık"
              solo
              hide-details="auto"
              flat
              dense
              class="rounded-lg"
              :error-messages="titleErrors()"
              v-model="$v.carInfo.title.$model"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-subheader>Model Yılı *</v-subheader>
            <v-select
              label="Year"
              solo
              item-text="value"
              item-value="id"
              hide-details
              flat
              dense
              class="rounded-lg"
              v-model="$v.carInfo.year.$model"
              :items="years"
              @change="yearChanged"
            >
            </v-select>
          </v-col>
          <v-col cols="12">
            <v-subheader>Marka *</v-subheader>
            <v-select
              label="Brand"
              solo
              item-text="value"
              item-value="id"
              hide-details
              flat
              dense
              class="rounded-lg"
              v-model="$v.carInfo.brand.$model"
              :items="getBrands"
              :returnObject="true"
              :disabled="brandIsDisabled"
              @change="brandChanged"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-subheader>Model *</v-subheader>
            <v-select
              label="Model"
              solo
              item-text="value"
              item-value="id"
              hide-details="auto"
              flat
              dense
              class="rounded-lg"
              :items="getModels"
              :returnObject="true"
              :disabled="modelIsDisabled"
              :error-messages="modelErrors()"
              v-model="$v.carInfo.model.$model"
              @change="modelChanged"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-subheader>Kasa Tipi *</v-subheader>
            <v-select
              label="Kasa Tipi *"
              solo
              item-text="value"
              item-value="id"
              hide-details="auto"
              flat
              dense
              class="rounded-lg"
              :items="getBodyTypes"
              :returnObject="true"
              :disabled="bodyTypeIsDisabled"
              :error-messages="bodyTypeErrors()"
              v-model="$v.carInfo.bodyType.$model"
              @change="bodyTypeChanged"
            >
            </v-select>
          </v-col>
          <v-col cols="12">
            <v-subheader>Vites *</v-subheader>
            <v-select
              label="Vites"
              solo
              item-text="value"
              item-value="id"
              hide-details="auto"
              flat
              dense
              class="rounded-lg"
              :items="getTransmissionTypes"
              :returnObject="true"
              :disabled="transmissionTypeIsDisabled"
              :error-messages="transmissionTypeErrors()"
              v-model="$v.carInfo.transmissionType.$model"
              @change="transmissionTypeChanged"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-subheader>Yakıt *</v-subheader>
            <v-select
              label="Yakıt"
              solo
              item-text="value"
              item-value="id"
              hide-details="auto"
              flat
              dense
              class="rounded-lg"
              :items="getFuelTypes"
              :returnObject="true"
              :disabled="fuelTypeIsDisabled"
              :error-messages="fuelTypeErrors()"
              v-model="$v.carInfo.fuelType.$model"
              @change="fuelTypeChanged"
            >
            </v-select>
          </v-col>
          <v-col cols="12">
            <v-subheader>Versiyon *</v-subheader>
            <v-select
              label="Versiyon"
              solo
              item-text="value"
              item-value="id"
              hide-details="auto"
              flat
              dense
              class="rounded-lg"
              :items="getVersions"
              :returnObject="true"
              :disabled="versionIsDisabled"
              :error-messages="versionErrors()"
              v-model="$v.carInfo.version.$model"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-subheader>Kilometre *</v-subheader>
            <v-text-field
              inputmode="numeric"
              label="Kilometre"
              solo
              hide-details="auto"
              flat
              dense
              class="rounded-lg"
              :error-messages="kilometersErrors()"
              v-model="$v.carInfo.kilometer.$model"
              @keyup="kilometersKeyPress"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-subheader class="leadgen-combobox-title">Renk *</v-subheader>
            <v-select
              label="Renk"
              solo
              item-text="value"
              item-value="id"
              hide-details="auto"
              flat
              dense
              class="rounded-lg"
              :items="colors"
              :returnObject="true"
              :error-messages="colorErrors()"
              v-model="$v.carInfo.color.$model"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col>
          <v-btn block color="primary" @click="continueProcess" :loading="isLoading"> Devam </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { GET_WIZARD_DATA } from '@/store/modules/carWizard.module';
import { ADD_CAR } from '@/store/modules/garage.module';
import { validationMixin } from 'vuelidate';
import { mapActions, mapGetters } from 'vuex';
import infoValidations from './infoValidations';
export default {
  name: 'Info',
  mixins: [validationMixin, infoValidations],
  props: {
    allow: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDreamCar: false,
      carInfo: {
        title: null,
        year: null,
        brand: null,
        model: null,
        bodyType: null,
        transmissionType: null,
        fuelType: null,
        version: null,
        kilometer: null,
        color: null,
        isDreamCar: false,
      },
      nf: new Intl.NumberFormat('tr-TR'),
    };
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'car',
      'years',
      'colors',
      'getBrands',
      'getModels',
      'getBodyTypes',
      'getTransmissionTypes',
      'getFuelTypes',
      'getVersions',
    ]),
    allowCarInsert() {
      return this.allow.allowCarInsert;
    },
    allowDreamCarInsert() {
      return this.allow.allowDreamCarInsert;
    },
    brandIsDisabled() {
      return this.getBrands.length === 0;
    },
    modelIsDisabled() {
      return this.getModels.length === 0;
    },
    bodyTypeIsDisabled() {
      return this.getBodyTypes.length === 0;
    },
    transmissionTypeIsDisabled() {
      return this.getTransmissionTypes.length === 0;
    },
    fuelTypeIsDisabled() {
      return this.getFuelTypes.length === 0;
    },
    versionIsDisabled() {
      return this.getVersions.length === 0;
    },
    validator() {
      return this.$v;
    },
  },
  created() {
    this.$nextTick(() => {
      if (this.car !== null) {
        this.carInfo = {
          key: this.car?.garId,
          title: this.car.garTitle,
          year: this.car.garYear,
          brand: { id: this.car.garCbrId },
          model: { id: this.car.garCmdId },
          bodyType: { id: this.car.garCbtId },
          transmissionType: { id: this.car.garCtrId },
          fuelType: { id: this.car.garCfuId },
          version: { id: this.car.garCvrId },
          color: { id: this.car.garClrId },
          kilometer: this.car.garKilometer,
          isDreamCar: this.car.garIsDream,
        };

        this.yearChanged(this.car.garYear, true);
      } else {
        this.carInfo = {
          key: null,
          title: null,
          year: null,
          brand: null,
          model: null,
          bodyType: null,
          transmissionType: null,
          fuelType: null,
          version: null,
          color: null,
          kilometer: 0,
          isDreamCar: null,
        };
      }
    });
  },
  methods: {
    ...mapActions({
      getWizardData: GET_WIZARD_DATA,
      addCar: ADD_CAR,
    }),
    yearChanged(year) {
      const payload = {
        year,
      };
      this.getWizardData({ payload, fillArrayField: 'brands' }).then(() => {
        this.brandChanged();
      });
    },
    brandChanged() {
      const payload = {
        year: this.carInfo.year,
        brandId: this.carInfo.brand?.id,
      };

      this.getWizardData({ payload, fillArrayField: 'models' }).then(() => {
        if (!this.getModels.some((x) => x.id === this.carInfo.model?.id)) {
          this.carInfo.model = null;
          return;
        }

        this.modelChanged();
      });
    },
    modelChanged() {
      const payload = {
        year: this.carInfo.year,
        brandId: this.carInfo.brand?.id,
        modelId: this.carInfo.model?.id,
      };
      this.getWizardData({ payload, fillArrayField: 'bodyTypes' }).then(() => {
        if (!this.getBodyTypes.some((x) => x.id === this.carInfo.bodyType?.id)) {
          if (this.getBodyTypes.length === 1) {
            this.carInfo.bodyType = this.getBodyTypes[0];
          } else {
            this.carInfo.bodyType = null;
            return;
          }
        }

        this.bodyTypeChanged();
      });
    },
    bodyTypeChanged() {
      const payload = {
        year: this.carInfo.year,
        brandId: this.carInfo.brand.id,
        modelId: this.carInfo.model.id,
        bodyTypeId: this.carInfo.bodyType.id,
      };
      this.getWizardData({ payload, fillArrayField: 'transmissionTypes' }).then(() => {
        if (!this.getTransmissionTypes.some((x) => x.id === this.carInfo.transmissionType?.id)) {
          if (this.getTransmissionTypes.length === 1)
            // eslint-disable-next-line prefer-destructuring
            this.carInfo.transmissionType = this.getTransmissionTypes[0];
          else {
            this.carInfo.transmissionType = null;
            return;
          }
        }

        this.transmissionTypeChanged();
      });
    },
    transmissionTypeChanged() {
      const payload = {
        year: this.carInfo.year,
        brandId: this.carInfo.brand.id,
        modelId: this.carInfo.model.id,
        bodyTypeId: this.carInfo.bodyType.id,
        transmissionTypeId: this.carInfo.transmissionType.id,
      };
      this.getWizardData({ payload, fillArrayField: 'fuelTypes' }).then(() => {
        if (!this.getFuelTypes.some((x) => x.id === this.carInfo.fuelType?.id)) {
          if (this.getFuelTypes.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            this.carInfo.fuelType = this.getFuelTypes[0];
          } else {
            this.carInfo.fuelType = null;
            return;
          }
        }

        this.fuelTypeChanged();
      });
    },
    fuelTypeChanged() {
      const payload = {
        year: this.carInfo.year,
        brandId: this.carInfo.brand.id,
        modelId: this.carInfo.model.id,
        bodyTypeId: this.carInfo.bodyType.id,
        transmissionTypeId: this.carInfo.transmissionType.id,
        fuelTypeId: this.carInfo.fuelType.id,
      };
      this.getWizardData({ payload, fillArrayField: 'versions' }).then(() => {
        if (!this.getVersions.some((x) => x.id === this.carInfo.version?.id)) {
          if (this.getVersions.length === 1) {
            this.carInfo.version = this.getVersions[0];
          } else {
            this.carInfo.version = null;
          }
        }
      });
    },
    kilometersKeyPress($event) {
      const text = $event.target.value.replace(/[^0-9]/g, '');
      this.carInfo.kilometer = this.nf.format(text);
      return true;
    },
    continueProcess() {
      this.validator.$touch();
      if (this.validator.$invalid) {
        return;
      }

      const data = {
        ...this.carInfo,
        kilometer: this.carInfo?.kilometer.toString().replace(/[^0-9]/g, ''),
      };

      const save = this.carInfo.isDreamCar;

      this.addCar({ save, data }).then(() => {
        if (!save) this.$emit('process', 'Damage');
        else {
          setTimeout(() => {
            this.$router.push({ name: 'garage' });
          }, 1000);
        }
      });
    },
  },
};
</script>

<style></style>
